export default defineNuxtRouteMiddleware((to) => {
  if (process.server) return;

  if (process.client) {
    const { posNavigation } = usePagePermission();
    const { path } = to;
    const item = posNavigation.value.find(({ route }) => route.includes(path));
    if (!item?.active) {
      return navigateTo("/empty-page");
    }
  }
});
